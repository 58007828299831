<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-three-line"
          class="mx-auto"
        />
      </v-col>
      <v-col cols="4">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-three-line"
          class="mx-auto"
        />
      </v-col>
      <v-col cols="4">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-three-line"
          class="mx-auto"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader
          ref="skeleton"
          type="card"
          class="mx-auto"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-three-line"
          class="mx-auto"
        />
      </v-col>
      <v-col cols="4">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-three-line"
          class="mx-auto"
        />
      </v-col>
      <v-col cols="4">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-three-line"
          class="mx-auto"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader
          ref="skeleton"
          type="card"
          class="mx-auto"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
    name: 'DashCollectionLoading'
}
</script>